import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';
import ScrollspyNav from 'react-scrollspy-nav';

import '../../generic-page.scss';

const CSLatentGearingPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="cs">
      <ul className="breadcrumb">
        <li>
          <Link to="/counter-side">Counter Side</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/counter-side/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Relic and latent gear</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/counterside/categories/guide_latent.png"
            alt="Latent gear"
          />
        </div>
        <div className="page-details">
          <h1>Relic and latent gear</h1>
          <h2>This guide explains how Latent gear works in Counter Side.</h2>
        </div>
      </div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader
                title="Foreword"
                subtitle="Guide created by Izuhii"
              />
              <p>
                Counter:Side is a live-service game which means things can and
                will change, for better or worse. Two most prominent examples
                are the CC-Resistance (now renamed to Debuff Resistance) and the
                DMG Calculation rework. This means the guide might become
                obsolete / less relevant.
              </p>
              <p>
                If it does happen, do let us know so we can make changes to the
                guide to keep them up-to-date.
              </p>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Relic Gear" />
              <p>
                Relic gears are T7 gears with unlockable 3rd stats called
                Latent. To unlock this you will need to enhance them to
                +2/+5/+7.
              </p>
              <StaticImage
                src="../../../images/counterside/gearing/relicgear.webp"
                alt="Relic gear"
              />
              <p>
                This Latent is permanent and random per gear, and you’ll only
                know what it is once you unlock it the first time (+2 unlock).
                The next two (+5 and +7) will only add more numbers and will not
                change the stat itself.
              </p>
              <p>
                Currently we have 3 types of Relic gears: Swift, Britra, and
                Inhibitor.
              </p>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Swift Relic" />
              <p>
                This gear is farmable from Relic Dungeon. You can get the gear
                from doing the stages or by crafting. You should always use the
                R rarity (no 2nd substat) as enhance fodders, and only work on
                SR or SSR gear to latent.
              </p>
              <h6>Weapon</h6>
              <ul>
                <li>Latent SSR - 1.7~6.5 %SPD/2.2~8.8 %CDR/1.2~4.8 %ASPD</li>
                <li>1st substat - 2.8 %SPD/2.2 %CDR/1.8 %ASPD</li>
                <li>2nd substat - 16% anti-x dmg</li>
              </ul>
              <h6>Armor</h6>
              <ul>
                <li>Latent SSR - 1.7~6.5 %SPD/2.2~8.8 %CDR/1.2~4.8 %ASPD</li>
                <li>1st substat - 2.8 %SPD/2.2 %CDR/1.8 %ASPD</li>
                <li>2nd substat - 16% anti-x res</li>
              </ul>
              <h6>Accessory</h6>
              <ul>
                <li>Latent SSR - 1.8~7.0 %SPD/2.5~9.7 %CDR/1.3~5.3 %ASPD</li>
                <li>1st substat - 3.1 %SPD/2.4 %CDR/2.0 %ASPD</li>
                <li>2nd substat - 17.6% anti-x dmg/anti-x res</li>
              </ul>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Britra Relic" />
              <p>
                This gear is farmable from Britra Raid. You can get the gear
                from clearing the Raid Milestone rewards or by crafting. Britra
                gear is very niche as it's centered around CDMG, CRIT HIT. So
                far the only unit known to use it best is Xiaolin.{' '}
              </p>
              <h6>Weapon</h6>
              <ul>
                <li>Latent SSR - 2.2~12.8 %CDMG/2.5~9.7 %rmDMG/0.8~6.5 %ATK</li>
                <li>1st substat - 8.0 %CDMG/36 CRIT/36 HIT</li>
                <li>2nd substat - 16% anti-x dmg</li>
              </ul>
              <h6>Armor</h6>
              <ul>
                <li>Latent SSR - 2.2~12.8 %CDMG/2.5~9.7 %rmDMG/0.8~6.5 %ATK</li>
                <li>1st substat - 8.0 %CDMG/36 CRIT/36 HIT</li>
                <li>2nd substat - 16% anti-x res</li>
              </ul>
              <h6>Accessory</h6>
              <ul>
                <li>
                  Latent SSR - 2.5~14.2 %CDMG/2.7~10.7 %rmDMG/1.0~7.0 %ATK
                </li>
                <li>1st substat - 8.8 %CDMG/40 CRIT/40 HIT</li>
                <li>2nd substat - 17.6% anti-x dmg/anti-x res</li>
              </ul>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="Inhibitor Relic" />
              <p>
                This gear is farmable from Inhibitor Raid. You can get the gear
                from clearing the Raid Milestone rewards or by crafting.
                Inhibitor gear is the best defensive gear as long as you can get
                the good latent roll.
              </p>
              <h6>Weapon</h6>
              <ul>
                <li>
                  Latent SSR - 8.0~32.0 %CDMGRES/2.5~9.7 %rmRES/2.2~8.8 %CDR
                </li>
                <li>1st substat - 4% Counter/Mech/Soldier res</li>
                <li>2nd substat - 16% anti-x res</li>
              </ul>
              <h6>Armor</h6>
              <ul>
                <li>Latent SSR - 8.0~32.0 %CDMGRES/2.5~9.7 %rmRES</li>
                <li>1st substat - 4% Counter/Mech/Soldier res</li>
                <li>2nd substat - 16% anti-x res</li>
              </ul>
              <h6>Accessory</h6>
              <ul>
                <li>Latent SSR - 8.8~35.2 %CDMGRES/2.7~10.7 %rmRES</li>
                <li>1st substat - 5% Counter/Mech/Soldier resT</li>
                <li>2nd substat - 17.6% anti-x dmg/anti-x res</li>
              </ul>
            </div>
            <div className="section-scroll" id="section-5">
              <SectionHeader title="Latent" />
              <p>
                Investing in Latent is really expensive and on average you need
                100k+ Eter (Relic Dungeon) or 50k+ Info (Raid) per gear (unless
                you got really lucky) and this is not counting the gold and
                enhance fodders needed to unlock latents. The number will go
                even higher if you are only checking latent on gear with a
                natural good set. This alone will discourage many people from
                min-maxing Latent, but for the rest, this is the real taste of
                what end-game gear grind is like.
              </p>
              <p>
                Once you get to this end-game, chances are your standard gears
                are already done (Humming, Maze, Gordias gears) and you still
                have Set Binaries piled. Rather than trying to save Set
                Binaries, you would statistically have more chance to get the
                best gear by trying out latent on any gear set and then use Set
                Binaries to tune them to appropriate sets. The flow of Relic and
                Latent farming will be something like:
              </p>
              <p>
                Farm Relic Gears &gt; Check +2 Latent socket &gt; If good, try
                +5/+7 sockets &gt; If good, use Set Binaries to tune the set.
              </p>
              <p>So far the latent gear sets worth farming for are:</p>
              <h6>Swift Relic ASPD set</h6>
              <p>(Swift Hands + Case + Crowns all ASPD latent)</p>
              <p>
                Swift Hands can get 6.6% ASPD max but you can use a T6/T7 Sc
                Hands with 4.8/5.1% as placeholder. Try to tune Accessories
                first, then Case, then Hands.
              </p>
              <ul>
                <li>Low Roll: 32.6% ASPD + 51.2% gDMG</li>
                <li>Average Roll: 40.2% ASPD + 51.2% gDMG</li>
                <li>Max Roll: 47.8% ASPD + 51.2% gDMG</li>
              </ul>
              <h6>Defensive Relic CDR set</h6>
              <p>
                (Inhibitor Hands + Maze Case + Swift Movements all CDR latent)
              </p>
              <p>
                Inhibitor Hands can get 8.8% CDR + 16% gRES, Maze Case can get
                11% CDR + 16% gRES, Swift Movement can get 12.1% CDR + 17.6%
                gRES.
              </p>
              <ul>
                <li>Low Roll: 53.0% CDR + 67.2% gRES</li>
                <li>Average Roll: 63.5% CDR + 67.2% gRES</li>
                <li>Max Roll: 74.0% CDR + 67.2% gRES</li>
              </ul>
              <h6>Defensive Relic HP set</h6>
              <p>
                (Inhibitor Hands + Maze Case + Gordias/Inhibitor acc all rmRES
                latent)
              </p>
              <p>
                Inhibitor Hands can get 9.7% rmRES + 4% tRES + 16% gRES, Maze
                Case can get 40% CDMGRES + 16% gRES, Gordias can get 13.2% rmRES
                + 17.6% gRES, Inhibitor acc can get 10.7% rmRES + 5% tRES +
                17.6% gRES.
              </p>
              <h6>With Gordias Accessory</h6>
              <ul>
                <li>
                  Low Roll: 4% tRES + 28.9% rmRES + 40% CDMGRES + 67.2% gRES
                </li>
                <li>
                  Average Roll: 4% tRES + 32.5% rmRES + 40% CDMGRES + 67.2% gRES
                </li>
                <li>
                  Max Roll: 4% tRES + 36.1% rmRES + 40% CDMGRES + 67.2% gRES
                </li>
              </ul>
              <h6>With Inhibitor Accessory</h6>
              <ul>
                <li>
                  Low Roll: 14% tRES + 7.9% rmRES + 40% CDMGRES + 67.2% gRES
                </li>
                <li>
                  Average Roll: 14% tRES + 19.5% rmRES + 40% CDMGRES + 67.2%
                  gRES
                </li>
                <li>
                  Max Roll: 14% tRES + 31.1% rmRES + 40% CDMGRES + 67.2% gRES
                </li>
              </ul>
              <h5>A word of warning</h5>
              <p>
                Gear tuning has no pity, unlike when you roll for a unit. This
                means that improper investment can potentially brick your
                account. Tip for new players will be:
              </p>
              <p>
                Always try to ask people with better knowledge and experience
                before committing to use your tuning/set binaries and before you
                sell/enhance a gear. We have a discord server with lots of
                helpful people willing to share, so do check it out.
              </p>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-5'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Foreword</a>
                </li>
                <li>
                  <a href="#section-1">Relic Gear</a>
                </li>
                <li>
                  <a href="#section-2">Swift Relic</a>
                </li>
                <li>
                  <a href="#section-3">Britra Relic</a>
                </li>
                <li>
                  <a href="#section-4">Inhibitor Relic</a>
                </li>
                <li>
                  <a href="#section-5">Latent</a>
                </li>
              </ul>
            </ScrollspyNav>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default CSLatentGearingPage;

export const Head: React.FC = () => (
  <Seo
    title="Relic and latent guide | Counter Side | Prydwen Institute"
    description="This guide explains how Latent gear works in Counter Side."
  />
);
